import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useParams } from 'react-router-dom'
import { ConsumptionService } from 'api/models'
import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import { Stack } from '@mui/system'
import { Edit, KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { useApp } from 'app/providers/app.provider'
import { OptionAndConsumptionServiceDetails } from 'modules/options/components/option-details.component'

export const ConsumptionServiceView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getConsumptionService } = useFetcher()
  const { id } = useParams()
  const { getRight } = useApp()
  const [consumption, setConsumption] = useState<ConsumptionService>({} as ConsumptionService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const refreshList = useCallback(async () => {
    if (!id) return
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getConsumptionService,
      data: Number(id),
      onSuccess: (consumption) => setConsumption(consumption),
      onEnd: () => setListIsLoading(false),
    })
  }, [id, getConsumptionService])

  useEffect(() => {
    refreshList().then()
  }, [id])

  return listIsLoading ? (
    <CardSkeleton />
  ) : (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/consumptions`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{ consumption.centerName ? `${t('consumptions_form')} | ${consumption.centerName}` : t('consumptions_form') }</Typography>
            {getRight('catalog_consumption', 'isEdit') && (
              <Link to={`/consumptions/${id}/edit`}>
                <Edit fontSize={'small'} />
              </Link>
            )}
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionServiceDetails service={consumption} center={consumption.centerId} />
    </Container>
  )
}
