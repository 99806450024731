import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import { FormItem, OptionService, OptionServiceBody, optionServiceBodySchema } from 'api/models'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { OptionAndConsumptionFormComponent } from 'modules/services/components/opt_and_cons_form.component'
import { ServiceEnum } from 'modules/services/enums/service_enum'

export const OptionEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { updateOptionService, getOptionService, getFormItems } = useFetcher()
  const { id } = useParams()
  const [optionService, setOptionService] = useState<OptionService>({} as OptionService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const [formItems, setFormItems] = useState({} as FormItem)
  const methods = useForm<OptionServiceBody>({
    mode: 'onChange',
    defaultValues: {
      end: null,
    },
    resolver: zodResolver(
      optionServiceBodySchema.refine((data) => {
        return data.savedLabels.find((sl) => sl.language === 1 && sl.label.trim() !== '')
      })
    ),
  })
  const { isValid } = methods.formState

  useEffect(() => {
    getData().then()
  }, [])

  const getData = useCallback(async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      data: Number(id),
      mutation: getOptionService,
      onSuccess: (optionService) => setOptionService(optionService),
      onEnd: () => setListIsLoading(false),
    })

    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getFormItems,
      data: ['options_services_types'],
      onSuccess: (data) => setFormItems(data.options_services_types as FormItem),
      onEnd: () => setListIsLoading(false),
    })
  }, [getOptionService, id])

  const handleSubmit = useCallback(
    async (data: OptionServiceBody) => {
      await handleMutation({
        mutation: updateOptionService,
        data: { id: Number(id), data: data },
        onSuccess: () => navigate(`/options/${id}`),
      })
    },
    [updateOptionService, id]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/options/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('option')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionFormComponent
        methods={methods}
        handleSubmit={handleSubmit}
        listIsLoading={listIsLoading}
        formItems={formItems}
        service={optionService}
        serviceType={ServiceEnum.OPTION}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
        {listIsLoading ? (
          <RectangularSkeleton />
        ) : (
          <>
            <Link to={`/options/${id}`}>
              <Button type={'button'} variant="outlined" size="small">
                {t('cancel')}
              </Button>
            </Link>
            <Button
              type={'submit'}
              variant="contained"
              size="small"
              disabled={!isValid}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </>
        )}
      </Box>
    </Container>
  )
}
