import React from 'react'
import { Form } from 'react-router-dom'
import { Container, Paper as MuiPaper } from '@mui/material'
import { CardSkeleton } from 'app/components/skeletons/card.skeleton'
import LanguageComponent from 'modules/services/components/languages.component'
import AvailabilityOptAndConsComponent from 'modules/services/components/availability_opti_and_cons.component'
import PriceOptionAndConsumptionComponent from 'modules/services/components/price_opt_and_cons.component'
import AccountingInformationsComponent from 'modules/services/components/accounting_informations.component'
import { UseFormReturn } from 'react-hook-form'
import OptAndConsInfosComponent from 'modules/services/components/option_and_cons_infos.component'

interface OptionAndConsumptionFormComponentProps {
  methods: UseFormReturn<any>
  handleSubmit: (data: any) => void
  listIsLoading: boolean
  formItems: any
  service: any
  serviceType: any
}

export const OptionAndConsumptionFormComponent = ({
  methods,
  handleSubmit,
  listIsLoading,
  formItems,
  service,
  serviceType,
}: OptionAndConsumptionFormComponentProps): React.JSX.Element => {
  const isFreePriceWatch = methods.watch('isFreePrice')
  const isFreeFeesWatch = methods.watch('isFreeFees')

  return (
    <Container>
      <Form onSubmit={methods.handleSubmit(handleSubmit)} autoComplete="off">
        <MuiPaper sx={{ marginTop: 4, padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <LanguageComponent control={methods.control} service={service} />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <OptAndConsInfosComponent
              type={serviceType}
              methods={methods}
              service={service}
              formItems={formItems}
            />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <AvailabilityOptAndConsComponent
              methods={methods}
              service={service}
              center={service.centerId}
            />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? (
            <CardSkeleton />
          ) : (
            <PriceOptionAndConsumptionComponent
              methods={methods}
              type={serviceType}
              isFreeFeesWatch={isFreeFeesWatch}
              isFreePriceWatch={isFreePriceWatch}
            />
          )}
        </MuiPaper>
        <MuiPaper sx={{ padding: 5, marginBottom: 5 }}>
          {listIsLoading ? <CardSkeleton /> : <AccountingInformationsComponent methods={methods} />}
        </MuiPaper>
      </Form>
    </Container>
  )
}
