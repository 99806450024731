import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ConsumptionService,
  ConsumptionsServiceBody,
  consumptionsServiceBodySchema,
  FormItem,
} from 'api/models'
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { OptionAndConsumptionFormComponent } from 'modules/services/components/opt_and_cons_form.component'
import { ServiceEnum } from 'modules/services/enums/service_enum'

export const ServiceConsumptionAdd = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { createConsumptionService, getOptionService, getFormItems } = useFetcher()
  const { id } = useParams()
  const [consumptionService] = useState<ConsumptionService>({} as ConsumptionService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const methods = useForm<ConsumptionsServiceBody>({
    mode: 'onChange',
    defaultValues: {
      isFreeFees: false,
      isFreePrice: false,
      isOnline: false,
      serviceAccounting: [
        {
          accountingNumber: '',
          analyticPlan: '',
          accountingName: '',
        },
      ],
    },
    resolver: zodResolver(
      consumptionsServiceBodySchema.refine((data) => {
        return data.savedLabels.find((sl) => sl.language === 1 && sl.label.trim() !== '')
      })
    ),
  })
  const navigate = useNavigate()
  const [formItems, setFormItems] = useState({} as FormItem)
  const { isValid } = methods.formState

  useEffect(() => {
    getData().then()
  }, [])

  const getData = useCallback(async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getFormItems,
      data: ['options_services_types'],
      onSuccess: (data) => setFormItems(data.options_services_types as FormItem),
      onEnd: () => setListIsLoading(false),
    })
  }, [getOptionService, id])

  const handleSubmit = useCallback(
    async (data: ConsumptionsServiceBody) => {
      await handleMutation({
        mutation: createConsumptionService,
        data: data,
        confirm: {
          title: t('confirm'),
          content: t('confirm_create_consumption'),
        },
        onSuccess: () => navigate(`/consumptions`),
      })
    },
    [createConsumptionService, id]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/consumptions`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('add_new_consumption')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionFormComponent
        methods={methods}
        handleSubmit={handleSubmit}
        listIsLoading={listIsLoading}
        formItems={formItems}
        service={consumptionService}
        serviceType={ServiceEnum.CONSUMPTION}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
        {listIsLoading ? (
          <RectangularSkeleton />
        ) : (
          <>
            <Link to={`/consumptions`}>
              <Button type={'button'} variant="outlined" size="small">
                {t('cancel')}
              </Button>
            </Link>
            <Button
              type={'submit'}
              variant="contained"
              size="small"
              disabled={!isValid}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </>
        )}
      </Box>
    </Container>
  )
}
