import { useTranslation } from 'react-i18next'
import React, { useMemo } from 'react'
import { ConsumptionService, OptionService } from 'api/models'
import { formatCurrency, formatDate, formatYesNo } from 'app/utils/format'
import { useApp } from 'app/providers/app.provider'
import InfoCard from 'app/components/card/info-card.component'
import { useFetcher } from 'app/providers/fetcher.provider'

interface IOptionDetailsProps {
  service: OptionService | ConsumptionService
  center: number | null
  children?: React.ReactNode
}

export const OptionAndConsumptionServiceDetails = ({ service, center }: IOptionDetailsProps) => {
  const { t } = useTranslation()
  const { getLanguages } = useApp()
  const { useGetServiceTypeAccountingById } = useFetcher()
  const { data: serviceTypeAccounting } = useGetServiceTypeAccountingById(service.serviceTypeId)

  const { savedLabels, info, accountingInfo, availability, price } = useMemo(() => {
    let savedLabels = getLanguages().map((language) => ({
      label: language.label,
      value:
        service.savedLabels.find((savedLabel) => savedLabel.language.id === language.id)?.label ??
        '',
    }))

    const info = [
      {
        label: t('typology'),
        value: service.serviceTypeLabel,
      },
    ]

    const price = [
      {
        label: 'monthlyPrice' in service ? t('installation_costs') : t('fees'),
        value: service.isFreeFees ? t('free') : formatCurrency(Number(service.commissioningFees)),
      },
      {
        label: t('free_fees'),
        value: t(formatYesNo(service.isFreeFees)),
      },
      {
        label: 'monthlyPrice' in service ? t('monthly_price') : t('price'),
        value: service.isFreePrice
          ? t('free')
          : formatCurrency(
              Number('monthlyPrice' in service ? service.monthlyPrice : service.price)
            ),
      },
      {
        label: t('free_price'),
        value: t(formatYesNo(service.isFreePrice)),
      },
    ]

    const availability = [
      {
        label: t('begin'),
        value: service.begin ? formatDate(service.begin) : '',
      },
      {
        label: t('end'),
        value: service.end ? formatDate(service.end) : '',
      },
      {
        label: center ? t('visible_from_client_space_for_this_center') : t('visible_client_space'),
        value: t(formatYesNo(service.isOnline)),
      },
      {
        label: center ? t('available_in_center') : t('available'),
        value: t(formatYesNo(service.isAvailable)),
      },
    ]

    const accountingInfo = [
      {
        label: t('accounting_number'),
        value: service.firstAccountingNumber ? (
          service.firstAccountingNumber
        ) : (
          <i>{serviceTypeAccounting?.accountingNumber}</i>
        ),
      },
      {
        label: t('analytic_plan'),
        value: service.firstAnalyticPlan ? (
          service.firstAnalyticPlan
        ) : (
          <i>{serviceTypeAccounting?.analyticPlan}</i>
        ),
      },
      {
        label: t('accounting_name'),
        value: service.firstAccountingName ? (
          service.firstAccountingName
        ) : (
          <i>{serviceTypeAccounting?.accountingName}</i>
        ),
      },
    ]

    return { savedLabels, info, accountingInfo, availability, price }
  }, [service, t, serviceTypeAccounting, getLanguages])

  return (
    <>
      <InfoCard title={t('label')} columns={savedLabels} />
      <InfoCard title={t('informations')} columns={info} />
      <InfoCard title={t('availability')} columns={availability} />
      <InfoCard title={t('price')} columns={price} />
      <InfoCard title={t('accounting_informations')} columns={accountingInfo} />
    </>
  )
}
