import React, { useCallback, useEffect, useState } from 'react'
import { useFetcher } from 'app/providers/fetcher.provider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ConsumptionService,
  FormItem,
  OptionServiceBody,
  optionServiceBodySchema,
} from 'api/models'
import { Container, Grid, Typography, Button, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { KeyboardBackspace } from '@mui/icons-material'
import { Link } from 'app/components/link.component'
import { useFeedback } from 'app/providers/feedback.provider'
import { RectangularSkeleton } from 'app/components/skeletons/rectangular.skeleton'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { OptionAndConsumptionFormComponent } from 'modules/services/components/opt_and_cons_form.component'
import { ServiceEnum } from 'modules/services/enums/service_enum'

export const ConsumptionServiceEditView = (): React.JSX.Element => {
  const { t } = useTranslation()
  const { getConsumptionService, updateConsumptionService, getFormItems } = useFetcher()
  const { id } = useParams()
  const [consumption, setConsumption] = useState<ConsumptionService>({} as ConsumptionService)
  const [listIsLoading, setListIsLoading] = useState<boolean>(true)
  const { handleMutation } = useFeedback()
  const navigate = useNavigate()
  const [formItems, setFormItems] = useState({} as FormItem)
  const methods = useForm<OptionServiceBody>({
    mode: 'onChange',
    resolver: zodResolver(
      optionServiceBodySchema.refine((data) => {
        if (!data.savedLabels) return false
        const fr = data.savedLabels.find((sl) => sl.language === 1)
        return (
          fr &&
          fr.label &&
          (data.isFreeFees || (data.commissioningFees && data.commissioningFees > 0)) &&
          (data.isFreePrice || (data.price && data.price > 0))
        )
      })
    ),
  })
  const { isValid } = methods.formState

  useEffect(() => {
    getData()
  }, [])

  const getData = useCallback(async () => {
    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getConsumptionService,
      data: Number(id),
      onSuccess: (data) => setConsumption(data),
      onEnd: () => setListIsLoading(false),
    })

    await handleMutation({
      onStart: () => setListIsLoading(true),
      mutation: getFormItems,
      data: ['consumption_services_types'],
      onSuccess: (data) => setFormItems(data.consumption_services_types as FormItem),
      onEnd: () => setListIsLoading(false),
    })
  }, [getConsumptionService, id])

  const handleSubmit = useCallback(
    async (data: OptionServiceBody) => {
      await handleMutation({
        confirm: {
          content: t('confirm_edit_consumption'),
        },
        mutation: updateConsumptionService,
        data: { id: Number(id), data: data },
        onSuccess: () => navigate(`/consumptions/${id}`),
      })
    },
    [updateConsumptionService, id]
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Link to={`/consumptions/${id}`} style={{ lineHeight: '1em' }}>
              <KeyboardBackspace fontSize={'small'} />
            </Link>
            <Typography variant="h1">{t('consumption_edit')}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <OptionAndConsumptionFormComponent
        methods={methods}
        handleSubmit={handleSubmit}
        listIsLoading={listIsLoading}
        formItems={formItems}
        service={consumption}
        serviceType={ServiceEnum.CONSUMPTION}
      />
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 5, marginTop: 5 }}>
        {listIsLoading ? (
          <RectangularSkeleton />
        ) : (
          <>
            <Link to={`/consumptions/${id}`}>
              <Button type={'button'} variant="outlined" size="small">
                {t('cancel')}
              </Button>
            </Link>
            <Button
              type={'submit'}
              variant="contained"
              size="small"
              disabled={!isValid}
              onClick={methods.control.handleSubmit(handleSubmit)}
            >
              {t('save')}
            </Button>
          </>
        )}
      </Box>
    </Container>
  )
}
