import { TitleComponent } from 'app/components/titles/title.component'
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ConsumptionService, FormItem, OptionService, OptionServiceBody } from 'api/models'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { ListSkeleton } from 'app/components/skeletons/list.skeleton'
import { Controller } from 'react-hook-form'
import { ServiceEnum } from 'modules/services/enums/service_enum'

type IProps =
  | {
      type: ServiceEnum.OPTION
      service: OptionService
      methods: UseFormReturn<OptionServiceBody>
      formItems: FormItem
    }
  | {
      type: ServiceEnum.CONSUMPTION
      service: ConsumptionService
      methods: UseFormReturn<OptionServiceBody>
      formItems: FormItem
    }

export default function OptAndConsInfosComponent({
  type,
  methods,
  service,
  formItems,
}: IProps) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isFreePriceWatch = methods.watch('isFreePrice')
  const isFreeFeesWatch = methods.watch('isFreeFees')

  useEffect(() => {
    if (!isLoading) {
      const defaultValue = formItems.values.filter(
        (type: any) => type.label === service.serviceTypeLabel
      )
      if (defaultValue.length > 0) {
        methods.setValue('serviceType', Number(defaultValue[0].id))
      }
    }
  }, [formItems, isLoading])

  useEffect(() => {
    if (isFreePriceWatch) {
      methods.setValue('price', null, { shouldValidate: true })
    }
    if (isFreeFeesWatch) {
      methods.setValue('commissioningFees', null, { shouldValidate: true })
    }
  }, [isFreeFeesWatch, isFreePriceWatch])

  useEffect(() => {
    methods.setValue(
      'commissioningFees',
      service.commissioningFees !== null ? Number(service.commissioningFees) : null
    )
    methods.setValue('isFreeFees', service.isFreeFees)
    methods.setValue(
      'price',
      type === 'consumption' ? Number(service.price) : Number(service.monthlyPrice)
    )
    methods.setValue('isFreePrice', service.isFreePrice)
    methods.setValue(
      'serviceAccounting.0.accountingNumber',
      service.firstAccountingNumber ?? ''
    )
    methods.setValue('serviceAccounting.0.analyticPlan', service.firstAnalyticPlan ?? '')
    methods.setValue('serviceAccounting.0.accountingName', service.firstAccountingName ?? '')
    setIsLoading(false)
  }, [service])

  return (
    <>
      <TitleComponent
        text={t('main_informations')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={4}
      />
      {isLoading ? (
        <ListSkeleton />
      ) : (
        <Grid container spacing={5} marginBottom={5}>
          <Grid container item xs={12} sm={6}>
            <Grid item xs={12} sm={8}>
              <Controller
                name="serviceType"
                control={methods.control}
                defaultValue={Number(formItems.values?.at(0)?.id)}
                render={({ field: { value, name } }) => {
                  return (
                    <FormControl fullWidth={true}>
                      <InputLabel id="serviceType-label">{t('type')}</InputLabel>
                      <Select
                        size={'small'}
                        fullWidth
                        value={value}
                        name={name}
                        label={t('type')}
                        onChange={(e) => methods.setValue(name, Number(e.target.value))}
                      >
                        {formItems.values?.map((v: any) => {
                          return (
                            <MenuItem key={v.id} value={Number(v.id)}>
                              {v.label}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}
