import { TitleComponent } from 'app/components/titles/title.component'
import { Divider, FormControlLabel, Grid, InputAdornment, Switch, TextField, Tooltip } from '@mui/material'
import { Euro } from '@mui/icons-material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { ServiceEnum } from 'modules/services/enums/service_enum'

interface IProps {
  methods: UseFormReturn<any>
  type: ServiceEnum.OPTION | ServiceEnum.CONSUMPTION
  isFreePriceWatch: boolean | undefined | null
  isFreeFeesWatch: boolean | undefined | null
}

export default function PriceOptionAndConsumptionComponent({
  methods,
  type,
  isFreeFeesWatch,
  isFreePriceWatch,
}: IProps) {
  const { t } = useTranslation()

  return (
    <>
      <TitleComponent
        text={t('price')}
        variant={'h3'}
        paddingTop={0}
        paddingLeft={12}
        marginBottom={1}
      />
      <Divider sx={{ marginBottom: 6 }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size={'small'}
            variant="outlined"
            type="number"
            label={type === ServiceEnum.CONSUMPTION ? t('fees') : t('installation_costs')}
            required={!isFreeFeesWatch}
            disabled={Boolean(isFreeFeesWatch)}
            sx={{
              '& .MuiInputBase-root.Mui-disabled': {
                backgroundColor: '#f5f5f5',
              },
            }}
            InputProps={{
              inputProps: {
                step: 0.01,
                min: 0,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Euro fontSize={'small'} />
                </InputAdornment>
              ),
            }}
            {...methods.register('commissioningFees', {
              valueAsNumber: true,
            })}
            data-cy={'fees'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tooltip title={t('tooltip_free_fees')} placement="top">
            <FormControlLabel
              control={
                <Switch
                  {...methods.register('isFreeFees')}
                  checked={methods.watch('isFreeFees') ?? false}
                  onChange={(e) => methods.setValue('isFreeFees', e.target.checked)}
                />
              }
              label={t('free_fees')}
              labelPlacement="start"
            />
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            size={'small'}
            variant="outlined"
            type="number"
            label={type === ServiceEnum.CONSUMPTION ? t('price') : t('monthly_price')}
            required={!isFreePriceWatch}
            disabled={Boolean(isFreePriceWatch)}
            sx={{
              '& .MuiInputBase-root.Mui-disabled': {
                backgroundColor: '#f5f5f5',
              },
            }}
            InputProps={{
              inputProps: {
                step: 0.01,
                min: 0,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Euro fontSize={'small'} />
                </InputAdornment>
              ),
            }}
            {...methods.register('price', {
              valueAsNumber: true,
            })}
            data-cy={'price'}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Tooltip title={t('tooltip_free_price')} placement="top">
            <FormControlLabel
              control={
                <Switch
                  {...methods.register('isFreePrice')}
                  checked={methods.watch('isFreePrice') ?? false}
                  onChange={(e) => methods.setValue('isFreePrice', e.target.checked)}
                />
              }
              label={t('free_price')}
              labelPlacement="start"
            />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}
